<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Editar Aluno</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items" />
    </div>
    <br />
    <infoBasica
      v-if="startComponent"
      @nextPage="nextPage"
      :pg="pg"
      :dadosPessoa="dadosPessoa"
    />
  </section>
</template>

<script>
import Step2 from "@/components/steps2.vue";
import infoBasica from "@/components/alunos/infoBasica.vue";

import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";
import { Aluno } from "@/class/alunos";

export default {
  props: {
    basic: {},
    id: {},
    pg: {},
  },
  components: {
    Step2,
    infoBasica,
  },
  name: "basico",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Editar Aluno" }],
      dadosPessoa: [],
      startComponent: false,
      items: [
        {
          number: "1",
          label: "Informações básicas",
          to: "aluno-basic-info-cadastrar",
          active: 1,
        },
        {
          number: "2",
          label: "Conectividade e acesso ao sistema",
          to: "2",
          active: 0,
        },
        {
          number: "3",
          label: "Endereço e Transporte",
          to: "3",
          active: 0,
        },
        {
          number: "4",
          label: "Confirmar",
          to: "4",
          active: 0,
        },
      ],
    };
  },
  methods: {
    nextPage(info) {
      this.$router.push({
        name: "login-pessoal-editar",
        params: { basic: JSON.stringify(info), pg: this.pg },
      });
    },
  },
  async beforeMount() {
    sessionStorage.setItem("cad",0);
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;

    } else {

      if (this.dadosPessoa.jaBuscou == undefined) {

        const data = await Aluno.obtemUm(this.id);
      
        const result = data.data;
        this.dadosPessoa.id = result[0].id;
        this.dadosPessoa.login_id = result[0].login_id;
        this.dadosPessoa.nome = result[0].nome;
        this.dadosPessoa.sobrenome = result[0].sobrenome;
        this.dadosPessoa.nomeMae = result[0].nome_mae;
        this.dadosPessoa.nomePai = result[0].nome_pai;
        this.dadosPessoa.observacao = result[0].observacao;
        this.dadosPessoa.certidaoNascimento = result[0].certidao_nascimento;
        this.dadosPessoa.certidaoCasamento = result[0].certidao_casamento;
        this.dadosPessoa.rg = result[0].rg;
        this.dadosPessoa.rgDataEmissao = result[0].rg_data_missao;
        this.dadosPessoa.cpf = result[0].cpf;
        this.dadosPessoa.cpf_responsavel = result[0].cpf_responsavel;
        this.dadosPessoa.cpfDele = result[0].cpf;
        this.dadosPessoa.dataNascimento = result[0].nascimento;
        this.dadosPessoa.sexo = result[0].sexo;
        this.dadosPessoa.matricula = result[0].matricula;
        this.dadosPessoa.observacao = result[0].observacao;
        this.dadosPessoa.possuiDeficiencia =
          result[0].deficiencia == 1 ? true : false;
        this.dadosPessoa.deficiencia = result[0].qual_deficiencia;
        this.dadosPessoa.cep = result[0].endereco.cep;
        this.dadosPessoa.logradouro = result[0].endereco.logradouro;
        this.dadosPessoa.numero = result[0].endereco.numero;
        this.dadosPessoa.complemento = result[0].endereco.complemento;
        this.dadosPessoa.bairro = result[0].endereco.bairro;
        this.dadosPessoa.cidade = result[0].endereco.cidade;
        this.dadosPessoa.estado = result[0].endereco.estado;
        this.dadosPessoa.nee = result[0].nee == 1 ? true : 0;
        this.dadosPessoa.mediacao_escolar =
          result[0].mediacao_escolar == 1 ? true : 0;
        this.dadosPessoa.email = result[0].login.email;
        this.dadosPessoa.emailDele = result[0].login.email;

        this.dadosPessoa.emailPadrao = result[0].login.email;
        this.dadosPessoa.telefone = [];
        this.dadosPessoa.id = result[0].id;
        this.dadosPessoa.matricula = result[0].matricula;

        this.dadosPessoa.alergico = result[0].alergico == 1 ? true : false;
        this.dadosPessoa.vacinacao = result[0].vacinacao == 1 ? true : false;

        
        this.dadosPessoa.tipo_alergia = result[0].tipo_alergia;
        this.dadosPessoa.tipo_sanguineo = result[0].tipo_sanguineo;
        this.dadosPessoa.usoDaImage = String(result[0].autorizar_uso_imagem);

        this.dadosPessoa.cor = result[0].cor_raca;
        this.dadosPessoa.conectividade = String(
          result[0].dispositivo_com_acesso_internet
        );
        this.dadosPessoa.religiao = result[0].religiao;
        this.dadosPessoa.aulas_religiao_frequentara = String(
          result[0].freq_aula_religioso
        );
        this.dadosPessoa.nacionalidade = result[0].nacionalidade;
        this.dadosPessoa.naturalidade = result[0].naturalidade;
        this.dadosPessoa.responsavel = result[0].nome_responsavel;
        this.dadosPessoa.transporte = result[0].transporte;
        this.dadosPessoa.utiliza_transporte = result[0].transporte;
        this.dadosPessoa.qual_transporte = result[0].qual_transporte;
        this.dadosPessoa.orgao_emissor = result[0].rg_orgao_emissor;
        this.dadosPessoa.uf_rg = result[0].rg_uf;
        this.dadosPessoa.sobrenome = result[0].sobrenome;

        this.dadosPessoa.livro_rg = result[0].livro;
        this.dadosPessoa.folha_rg = result[0].folha;
        this.dadosPessoa.termo_rg = result[0].termo;

        this.dadosPessoa.nis = result[0].nis;
        this.dadosPessoa.sus = result[0].sus;

        this.dadosPessoa.livro_rg = result[0].livro;
        this.dadosPessoa.folha_rg = result[0].folha;
        this.dadosPessoa.termo_rg = result[0].termo;

        this.dadosPessoa.renda_per_capita = result[0].renda_per_capita;
        this.dadosPessoa.numero_pessoas_residencia = result[0].numero_pessoas_residencia;
        this.dadosPessoa.bolsa_familia =  String(result[0].bolsa_familia);
        this.dadosPessoa.email_responsavel = result[0].email_responsavel;




        this.dadosPessoa.tipos_dispotivos = [];
        if (result[0].acesso_celular)
          {this.dadosPessoa.tipos_dispotivos.push("CELULAR");}
        if (result[0].acesso_computador)
          {this.dadosPessoa.tipos_dispotivos.push("COMPUTADOR");}

        if (result[0].acesso_tablet)
          {this.dadosPessoa.tipos_dispotivos.push("TABLET");}

        if (result[0].acesso_outros)
          {this.dadosPessoa.tipos_dispotivos.push("OUTROS");}

        this.dadosPessoa.estadocivil = result[0].estado_civil;

        this.dadosPessoa.jaBuscou = true;
        for (const el of result[0].telefone) {
          this.dadosPessoa.telefone.push({
            id: el.id,
            nome: el.telefone,
            identificador: el.identificador,
          });
        }
      } else {

        this.info.nome =
          this.dadosPessoa.nome != undefined
            ? this.dadosPessoa.nome
            : this.info.nome;
        this.info.sobrenome =
          this.dadosPessoa.sobrenome != undefined
            ? this.dadosPessoa.sobrenome
            : this.info.sobrenome;
        this.info.nomeMae =
          this.dadosPessoa.nomeMae != undefined
            ? this.dadosPessoa.nomeMae
            : this.info.nomeMae;
        this.info.nomePai = this.dadosPessoa.nomePai;
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia = this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.mediacao_escolar = this.dadosPessoa.mediacao_escolar;
        this.info.email = this.dadosPessoa.email;
        this.info.senha = this.dadosPessoa.senha;
        this.info.confirmarSenha = this.dadosPessoa.confirmarSenha;
        this.info.estadocivil =
          this.dadosPessoa.estadocivil != undefined
            ? this.dadosPessoa.estadocivil
            : this.info.estadocivil;
        this.info.certidaoNascimento = this.dadosPessoa.certidaoNascimento;
        this.info.certidaoCasamento = this.dadosPessoa.certidaoCasamento;
        this.info.rg = this.dadosPessoa.rg;
        this.info.rgDataEmissao =
          this.dadosPessoa.rgDataEmissao != undefined
            ? this.dadosPessoa.rgDataEmissao
            : this.info.rgDataEmissao;
        this.info.cpf = this.dadosPessoa.cpf;
        this.info.cpfDele = this.dadosPessoa.cpfDele;
        this.info.cpf_responsavel = this.dadosPessoa.cpf_responsavel;


        this.info.dataNascimento =
          this.dadosPessoa.dataNascimento != undefined
            ? this.dadosPessoa.dataNascimento
            : this.info.dataNascimento;
        this.info.cep = this.dadosPessoa.cep;
        this.info.estado = this.dadosPessoa.estado;
        this.info.cidade = this.dadosPessoa.cidade;
        this.info.bairro = this.dadosPessoa.bairro;
        this.info.logradouro = this.dadosPessoa.logradouro;
        this.info.numero = this.dadosPessoa.numero;
        this.info.complemento = this.dadosPessoa.complemento;
        this.info.telefone =
          this.dadosPessoa.telefone == undefined
            ? []
            : this.dadosPessoa.telefone;
        this.info.sexo =
          this.dadosPessoa.sexo != undefined
            ? this.dadosPessoa.sexo
            : this.info.sexo;
        this.info.foto = this.dadosPessoa.foto; //(this.dadosPessoa.foto != undefined ? this.dadosPessoa.foto : this.info.foto);
        this.info.id =
          this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id;
        this.dadosPessoa.jaBuscou = true;
        this.info.matricula = this.dadosPessoa.matricula;
        this.info.alergico =
          this.dadosPessoa.alergico != undefined
            ? this.dadosPessoa.alergico
            : this.info.alergico;

        this.info.vacinacao =
          this.dadosPessoa.vacinacao != undefined
            ? this.dadosPessoa.vacinacao
            : this.info.vacinacao;


        this.info.tipo_alergia =
          this.dadosPessoa.tipo_alergia != undefined
            ? this.dadosPessoa.tipo_alergia
            : this.info.tipo_alergia;
        this.info.nacionalidade =
          this.dadosPessoa.nacionalidade != undefined
            ? this.dadosPessoa.nacionalidade
            : this.info.nacionalidade;
        this.info.naturalidade =
          this.dadosPessoa.naturalidade != undefined
            ? this.dadosPessoa.naturalidade
            : this.info.naturalidade;
        this.info.cor =
          this.dadosPessoa.cor != undefined
            ? this.dadosPessoa.cor
            : this.info.cor;
        this.info.religiao =
          this.dadosPessoa.religiao != undefined
            ? this.dadosPessoa.religiao
            : this.info.religiao;
        this.info.aulas_religiao_frequentara =
          this.dadosPessoa.aulas_religiao_frequentara != undefined
            ? this.dadosPessoa.aulas_religiao_frequentara
            : "0";
        this.info.usoDaImage =
          this.dadosPessoa.usoDaImage != undefined
            ? this.dadosPessoa.usoDaImage
            : "0";
        this.info.responsavel =
          this.dadosPessoa.responsavel != undefined
            ? this.dadosPessoa.responsavel
            : "";
        this.info.orgao_emissor =
          this.dadosPessoa.orgao_emissor != undefined
            ? this.dadosPessoa.orgao_emissor
            : "";
        this.info.uf_rg =
          this.dadosPessoa.uf_rg != undefined ? this.dadosPessoa.uf_rg : "";
        this.info.livro_rg =
          this.dadosPessoa.livro_rg != undefined
            ? this.dadosPessoa.livro_rg
            : "";
        this.info.folha_rg =
          this.dadosPessoa.folha_rg != undefined
            ? this.dadosPessoa.folha_rg
            : "";
        this.info.termo_rg =
          this.dadosPessoa.termo_rg != undefined
            ? this.dadosPessoa.termo_rg
            : "";

        this.info.nis =
          this.dadosPessoa.nis != undefined ? this.dadosPessoa.nis : "";
        this.info.sus =
          this.dadosPessoa.sus != undefined ? this.dadosPessoa.sus : "";

        this.info.conectividade =
          this.dadosPessoa.conectividade != undefined
            ? this.dadosPessoa.conectividade
            : "1";
        this.info.tipos_dispotivos =
          this.dadosPessoa.tipos_dispotivos != undefined
            ? this.dadosPessoa.tipos_dispotivos
            : [];
        this.info.tipo_sanguineo =
          this.dadosPessoa.tipo_sanguineo != undefined
            ? this.dadosPessoa.tipo_sanguineo
            : "";
        this.info.utiliza_transporte =
          this.dadosPessoa.utiliza_transporte != undefined
            ? this.dadosPessoa.utiliza_transporte
            : "1";
        this.info.qual_transporte =
          this.dadosPessoa.qual_transporte != undefined
            ? this.dadosPessoa.qual_transporte
            : "";
        this.info.estadocivil =
          this.dadosPessoa.estadocivil != undefined
            ? this.dadosPessoa.estadocivil
            : "";

        this.info.renda_per_capita = this.dadosPessoa.renda_per_capita != undefined
            ? this.dadosPessoa.renda_per_capita
            : "";

        this.info.numero_pessoas_residencia = this.dadosPessoa.numero_pessoas_residencia != undefined
            ? this.dadosPessoa.numero_pessoas_residencia
            : "";
        
        this.info.bolsa_familia = this.dadosPessoa.bolsa_familia != undefined
            ? this.dadosPessoa.bolsa_familia
            : "";

        this.info.email_responsavel = this.dadosPessoa.email_responsavel != undefined
            ? this.dadosPessoa.email_responsavel
            : "";
      }
    }
    this.startComponent = true;

  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
